<template>
	<div>	
		<div v-if="userPlaylists != null && userPlaylists.length > 0">
			<PlaylistPersonalItem v-for="(up, idx) in sortedUserWorkouts" :inactive="idx > 0 && !premiumStatus.validTo" :key="up.uid" @startPlaylist="startUserPlaylist(up.uid)" @editPlaylistPersonalItemRestricted="editItem(up.uid, true)" @editPlaylistPersonalItem="editItem(up.uid, false)" :title="up.name" :number="up.items.length"></PlaylistPersonalItem>
		</div>

		<EditPlaylistItemModal :restricted="restrictedEditModal" @playlistDeleted=playlistDeleted :userPlaylist="currentUserPlaylist" name="editPlaylistItemModal"></EditPlaylistItemModal>
	</div>
</template>

<script>
import PlaylistPersonalItem from "@/components/PlaylistPersonalItem.vue";
import EditPlaylistItemModal from "@/components/EditPlaylistItemModal.vue";


export default {
    name: 'PlaylistsPersonal',
	components: { PlaylistPersonalItem, EditPlaylistItemModal },
	methods: {
		editItem(uid, restricted) {
			this.restrictedEditModal = restricted;
			let userPlaylist = this.userPlaylists.find(up => up.uid == uid);
			this.currentUserPlaylist = userPlaylist;
			this.$modal.show("editPlaylistItemModal");
		},
		init() {
			this.$store.dispatch('fetchAllUserPlaylists').then((res) => {
                this.userPlaylists = res;
            });
		},
		startUserPlaylist(uid) {
            this.$router.push("/playlist/start?user_playlist_id=" + uid);
		},
		playlistDeleted(playlist) {
			this.$modal.hide("editPlaylistItemModal");
			this.userPlaylists = this.userPlaylists.filter(i => i.uid != playlist.uid);
		}
	},
	mounted() {
		this.init();
	},
	data() {
		return {
			userPlaylists: [],
			currentUserPlaylist: null,
			restrictedEditModal: false,
		}
	},
	computed: {
		premiumStatus() {
            return this.$store.getters.getPremiumStatus;
        },
		sortedUserWorkouts() {
			let userWorkouts = [...this.userPlaylists];
			return userWorkouts.sort((b, a) => (a.creationDate.localeCompare(b.creationDate)))
		}
	}
}
</script>

<style lang="scss" scoped>
</style>